import { AppBar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ThemeProvider, Toolbar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import NetBeacon from '../assets/nb-reporter_horizontal_white.png';
import { RealmContext } from '../contexts/ReactRealmProvider';
import { toolbarButtonsTheme } from '../themes/toolbarButtonsTheme';

export const IncidentVote = () => {
  const { user, callFunction, login } = useContext(RealmContext);
  const { vote, incidentId, settingsId } = useParams();
  const score = vote === 'good' ? 1 : -1;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    login('anonymous').then((u) => {
      console.log({ logIn: u });
      u.functions['setIncidentScore'](settingsId, incidentId, score).then((r) => {
        console.log({ setIncidentScore: r });
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        u.logOut().then((r) => {
          console.log({ logOut: r });
          setLoading(false);
        });
      });
    });
  }, []);

  if (!loading) {
    console.log(`${settingsId} voted ${vote} (${score}) for ${incidentId}`);
  }

  return (
    <>
      <ThemeProvider theme={toolbarButtonsTheme}>
        <AppBar position="sticky">
          <Toolbar>
            <Box sx={{ mr: 4, flexGrow: 1 }}>
              <Link to='/'><img src={NetBeacon} height={30} /></Link>
            </Box>
            {/* <Button component={Link} to="/privacy">Privacy Notice</Button> */}
            {/* <Button component={Link} to="/terms">Terms of Use</Button> */}
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Container>
        <Dialog open={!Boolean(loading)}>
          <DialogTitle>Notice</DialogTitle>
          <DialogContent>
            <DialogContentText>Your incident score has been tallied. You may close this window.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setLoading(true);
            }}>Okay</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};