import { AppBar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ThemeProvider, Toolbar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NetBeacon from '../../assets/nb-reporter_horizontal_white.png';
import { useEmbedded } from '../../hooks/useEmbedded';
import { RealmError } from '../RealmError';
import { RealmContext } from '../../contexts/ReactRealmProvider';
import { toolbarButtonsTheme } from '../../themes/toolbarButtonsTheme';

export const ConfirmUser = () => {
  const { confirm } = useContext(RealmContext);
  const { isEmbedded } = useEmbedded();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const tokenId = query.get('tokenId');
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    confirm(token, tokenId).then(() => {
      setConfirmed(true);
    }).catch((error) => {
      setError(error);
    })
  }, [confirm, token, tokenId]);

  if (false && isEmbedded) {
    return (
      <Dialog open={Boolean(confirmed || error)}>
        <DialogTitle>Notice</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmed ? "Your email has been verified and you may now log in. You can close this window." : <RealmError error={error} />}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setError();
            setConfirmed();
          }}>Okay</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <ThemeProvider theme={toolbarButtonsTheme}>
        <AppBar position="sticky">
          <Toolbar>
            <Box sx={{ mr: 4, flexGrow: 1 }}>
              <Link to='/'><img src={NetBeacon} height={30} /></Link>
            </Box>
            {/* <Button component={Link} to="/privacy">Privacy Notice</Button> */}
            {/* <Button component={Link} to="/terms">Terms of Use</Button> */}
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Container>
        <Dialog open={Boolean(confirmed || error)}>
          <DialogTitle>Notice</DialogTitle>
          <DialogContent>
            <DialogContentText>{confirmed ? "Your email has been verified. You may now log in." : <RealmError error={error} />}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setError();
              setConfirmed();
              window.location.href = "/login";
            }}>Okay</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
};