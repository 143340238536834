import { useContext, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { RealmContext } from "./contexts/ReactRealmProvider";
import { SessionTimeoutProvider } from "./contexts/SessionTimeoutProvider";
import { TopBar } from "./components/TopBar";
import { BottomBar } from "./components/BottomBar";
import { DataContext } from "./contexts/DataProvider";
import { Login } from "./routes/Login";
import SuspenseReact from "./Suspense";

const Logout = lazy(() => import("./routes/Logout"));
const MainScreen = lazy(() => import("./routes/MainScreen"));
const Terms = lazy(() => import("./routes/Terms"));
const RecipTerms = lazy(() => import("./routes/RecipTerms"));
const Privacy = lazy(() => import("./routes/Privacy"));
const UsersAdmin = lazy(() => import("./routes/admin/Users"));
const CCtldsAdmin = lazy(() => import("./routes/admin/CCtldsAdmin"));
const RegistrarsAdmin = lazy(() => import("./routes/admin/Registrars"));
const ProtectedDomainsAdmin = lazy(() => import("./routes/admin/ProtectedDomains"));
const Dashboard = lazy(() => import("./routes/admin/Dashboard"));
const IncidentsRoot = lazy(() => import("./routes/Incidents/IncidentsRoot"));
const Incidents = lazy(() => import("./routes/Incidents"));
const AbuseIncident = lazy(() => import("./routes/Incidents/AbuseIncident"));
const Reports = lazy(() => import("./routes/Reports"));
const AbuseReport = lazy(() => import("./routes/Reports/AbuseReport"));
const ReportsRoot = lazy(() => import("./routes/Reports/ReportsRoot"));
const MakeNewReport = lazy(() => import("./routes/MakeNewReport"));
const NewReportRoot = lazy(() =>
  import("./routes/MakeNewReport/NewReportRoot")
);
const ReportHistory = lazy(() => import("./routes/account/AccountReports"));
const TestAPI = lazy(() => import("./routes/account/TestAPI"));
const Account = lazy(() => import("./routes/account"));
const Signup = lazy(() => import("./routes/Signup"));
const AccountReportsRoot = lazy(() =>
  import("./routes/account/AccountReports/AccountReportsRoot")
);
const AccountAbuseReport = lazy(()=> import("./routes/account/AccountReports/AccountAbuseReport"));
const EditAccountAbuseReport = lazy(()=> import("./routes/account/AccountReports/EditAccountAbuseReport"));

export const AppRoutes = ({}) => {
  const { user, customData = emptyObject } = useContext(RealmContext);
  const { loading } = useContext(DataContext);
  const loggedIn = user?.isLoggedIn;
  const isAdmin = customData.type === "admin";
  const { active, approved } = customData;
  const location = useLocation();

  return (
    <Routes>
      <Route
        path="*"
        element={
          <>
            <TopBar />
            <Box
              mt={1}
              sx={{
                paddingBottom: "6rem",
                marginLeft: "2rem",
                marginRight: "2rem",
              }}
            >
              {!loggedIn ? (
                <Routes>
                  <Route
                    path="privacy"
                    element={
                      <SuspenseReact>
                        <Privacy />
                      </SuspenseReact>
                    }
                  />
                  <Route
                    path="terms"
                    element={
                      <SuspenseReact>
                        <Terms />
                      </SuspenseReact>
                    }
                  />
                  <Route
                    path="recipient-terms"
                    element={
                      <SuspenseReact>
                        <RecipTerms />
                      </SuspenseReact>
                    }
                  />
                  <Route
                    path="signup"
                    element={
                      <SuspenseReact>
                        <Signup />
                      </SuspenseReact>
                    }
                  />
                  <Route path="login" element={<Login />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              ) : (
                <Routes>
                  {loading ? (
                    <Route
                      path="*"
                      element={
                        <Backdrop open>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      }
                    />
                  ) : (
                    <>
                      <Route path="/" element={<SessionTimeoutProvider />}>
                        <Route
                          path="/login"
                          element={
                            <Navigate to={location.state?.pathname || "/"} />
                          }
                        />
                        <Route
                          path="/logout"
                          element={
                            <SuspenseReact>
                              <Logout />
                            </SuspenseReact>
                          }
                        />
                        {isAdmin && (
                          <>
                            <Route
                              path="admin/users"
                              element={
                                <SuspenseReact>
                                  <UsersAdmin />
                                </SuspenseReact>
                              }
                            />
                            <Route
                              path="admin/cctlds"
                              element={
                                <SuspenseReact>
                                  <CCtldsAdmin />
                                </SuspenseReact>
                              }
                            />
                            <Route
                              path="/admin/registrars"
                              element={
                                <SuspenseReact>
                                  <RegistrarsAdmin />
                                </SuspenseReact>
                              }
                            />
                            <Route
                              path="/admin/dashboard"
                              element={
                                <SuspenseReact>
                                  <Dashboard mode="admin" />
                                </SuspenseReact>
                              }
                            />
                            <Route
                              path="/admin/protected-domains"
                              element={
                                <SuspenseReact>
                                  <ProtectedDomainsAdmin />
                                </SuspenseReact>
                              }
                            />
                          </>
                        )}
                        {customData.type === "registry" && (
                          <Route
                            path="/dashboard"
                            element={
                              <SuspenseReact>
                                <Dashboard mode="registry" />
                              </SuspenseReact>
                            }
                          />
                        )}
                        <Route
                          path="/reports"
                          element={
                            <SuspenseReact>
                              <ReportsRoot />
                            </SuspenseReact>
                          }
                        >
                          <Route
                            index
                            element={
                              <SuspenseReact>
                                <Reports />
                              </SuspenseReact>
                            }
                          />
                          <Route
                            path="view/:id"
                            element={
                              <SuspenseReact>
                                <AbuseReport />
                              </SuspenseReact>
                            }
                          />
                        </Route>

                        <Route
                          path="/new-report/:type?/:id?"
                          element={
                            <SuspenseReact>
                              <NewReportRoot />
                            </SuspenseReact>
                          }
                        >
                          <Route
                            index
                            element={
                              <SuspenseReact>
                                <MakeNewReport />
                              </SuspenseReact>
                            }
                          />
                        </Route>

                        {approved && customData.type !== "reporter" && (
                          <Route
                            path="/incidents"
                            element={
                              <SuspenseReact>
                                <IncidentsRoot />
                              </SuspenseReact>
                            }
                          >
                            <Route
                              index
                              element={
                                <SuspenseReact>
                                  <Incidents />
                                </SuspenseReact>
                              }
                            />
                            <Route
                              path="view/:id"
                              element={
                                <SuspenseReact>
                                  <AbuseIncident />
                                </SuspenseReact>
                              }
                            />
                          </Route>
                        )}

                        {approved && (
                          <Route
                            path="/account/reports"
                            element={
                              <SuspenseReact>
                                <AccountReportsRoot />
                              </SuspenseReact>
                            }
                          >
                            <Route
                              index
                              element={
                                <SuspenseReact>
                                  <ReportHistory />
                                </SuspenseReact>
                              }
                            />
                            <Route
                              path="view/:id"
                              element={
                                <SuspenseReact>
                                  <AccountAbuseReport />
                                </SuspenseReact>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <SuspenseReact>
                                  <EditAccountAbuseReport />
                                </SuspenseReact>
                              }
                            />
                          </Route>
                        )}
                        <Route
                          path="/account/api"
                          element={
                            <SuspenseReact>
                              <TestAPI />
                            </SuspenseReact>
                          }
                        />
                        <Route
                          path="/account"
                          element={
                            <SuspenseReact>
                              <Account />
                            </SuspenseReact>
                          }
                        />

                        <Route path="/signup" element={<Navigate to="/" />} />
                        <Route
                          path="/"
                          element={
                            <SuspenseReact>
                              <MainScreen />
                            </SuspenseReact>
                          }
                        />
                        <Route
                          path="/terms"
                          element={
                            <SuspenseReact>
                              <Terms />
                            </SuspenseReact>
                          }
                        />
                        <Route
                          path="/recipient-terms"
                          element={
                            <SuspenseReact>
                              <RecipTerms />
                            </SuspenseReact>
                          }
                        />
                        <Route
                          path="/privacy"
                          element={
                            <SuspenseReact>
                              <Privacy />
                            </SuspenseReact>
                          }
                        />
                        <Route path="*" element={<div>[404 Not Found]</div>} />
                      </Route>
                    </>
                  )}
                </Routes>
              )}
            </Box>
            <BottomBar />
          </>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
