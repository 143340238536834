import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Realm from 'realm-web';
const convertEJSON = (_) => Realm.BSON.EJSON.parse(JSON.stringify(_));

export const RealmContext = createContext({});

export const RealmProvider = ({ id, render = null, remember = true, children = null }) => {
    const app = useMemo(() => new Realm.App({ id }), [id]);

    const [user, setUser] = useState((remember && app.currentUser?.isLoggedIn && app.currentUser) || false);
    const [customData, setCustomData] = useState(convertEJSON((user?._accessToken && user?.customData) || {}));
    const [loading, setLoading] = useState(false);
    const mongo = useMemo(() => user && user.mongoClient('mongodb-atlas'), [user]);

    const linkUser = useCallback(async (user, how, ...creds) => {
        if (!creds[0].realmId) {
            const linkedCreds = Realm.Credentials[how](...creds);
            return user.linkCredentials(linkedCreds).then(() => {
                return user.refreshCustomData().then(() => {
                    setCustomData(convertEJSON(user.customData || {}));
                });
            });
        }
        else {
            throw new Error('An account already exists for this email address.');
        }
    }, []);

    const register = useCallback(async (email, password, onRegister = null) => {
        const registration = await app.emailPasswordAuth.registerUser(email, password);
        if (onRegister) await onRegister(registration);
        return registration;
    }, []);

    const resetPassword = useCallback(async (token, tokenId, password, onResetPassword = null) => {
        const reset = await app.emailPasswordAuth.resetPassword(token, tokenId, password);
        if (onResetPassword) await onResetPassword(reset);
        return reset;
    }, []);

    const confirm = useCallback(async (token, tokenId, onConfirm = null) => {
        const confirmation = await app.emailPasswordAuth.confirmUser(token, tokenId);
        if (onConfirm) await onConfirm(confirmation);
        return confirmation;
    }, []);

    const login = useCallback(async (how, ...creds) => {
        setLoading(true);
        const onLogin = ('function' === typeof creds[creds.length - 1]) && creds.pop();
        const credentials = how === 'function' ? Realm.Credentials.function(...creds) : Realm.Credentials[how](...creds);

        const user = await app.logIn(credentials)
            .catch((error) => {
                // console.warn(error.essage);
                setLoading(false);
                throw error;
            });

        if (onLogin) await onLogin(user).catch((error) => {
            user.logOut();
            setLoading(false);
            throw error;
        });

        return await user.refreshCustomData().then(() => {
            setCustomData(convertEJSON(user.customData || {}));
            setUser(user);
            setLoading(false);
            return user;
        }).catch((error) => {
            setLoading(false);
            throw error;
        });
    }, [app]);

    const logout = useCallback(async (onLogout = null) => {
        return user && user.logOut().then(() => {
            setUser(false);
            return onLogout && onLogout();
        }).catch((error) => {
            console.error('logOut failed');
            // console.error(error);
        });
    }, [user]);

    const refreshCustomData = useCallback(() => user.refreshCustomData().then(() => setCustomData(convertEJSON(user.customData))), [user]);

    const callFunction = useCallback((func, ...args) => user.functions[func](...args), [user]);

    useEffect(() => {
        user && refreshCustomData();
    }, [user]);

    const context = {
        Realm,
        app,
        loading,
        user,
        customData,
        mongo,
        isLoggedIn: Boolean(user?.isLoggedIn),
        login,
        logout,
        linkUser,
        register,
        confirm,
        resetPassword,
        callFunction,
        refreshCustomData,
    };

    return (
        <RealmContext.Provider value={context}>
            {render ? render(context) : children}
        </RealmContext.Provider>
    );
};

export const withRealm = (Component) => {
    return ({ id }) => <RealmProvider id={id} render={(realm) => <Component realm={realm} />} />;
};