import { AppBar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ThemeProvider, Toolbar, Typography, TextField } from '@mui/material';
import { useContext, useState, useCallback, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import NetBeacon from '../assets/nb-reporter_horizontal_white.png';
import { RealmContext } from '../contexts/ReactRealmProvider';
import { toolbarButtonsTheme } from '../themes/toolbarButtonsTheme';

export const IncidentVote = () => {
  const { login } = useContext(RealmContext);
  const { incidentId, settingsId, status } = useParams();
  const [showDialog, setShowDialog] = useState(true);
  const [feedback, setFeedback] = useState('');

  const handleSubmit = useCallback((additionalFeedback = '', update = false) => {
    login('anonymous').then((u) => {
      u.functions['setIncidentScore'](settingsId, incidentId, status, additionalFeedback, update).then((r) => {
        // console.log({ setIncidentScore: r });
      }).catch((error) => {
        console.error(error.message);
      })
        .finally(() => {
          u.logOut().then((r) => {
            // console.log({ logOut: r });
          });
        });
    }).catch((error) => {
      console.error(error.message);
    });
  }, [login, settingsId, incidentId, status]);

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleDialogClose = () => {
    setShowDialog(false);
    handleSubmit(feedback, true);
  }

  return (
    <>
      <ThemeProvider theme={toolbarButtonsTheme}>
        <AppBar position="sticky">
          <Toolbar>
            <Box sx={{ mr: 4, flexGrow: 1 }}>
              <Link to='/'><img src={NetBeacon} height={30} /></Link>
            </Box>
            {/* <Button component={Link} to="/privacy">Privacy Notice</Button> */}
            {/* <Button component={Link} to="/terms">Terms of Use</Button> */}
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Container sx={{ maxWidth: '1000px' }}>
        <Dialog open={Boolean(showDialog)} maxWidth="sm" fullWidth>
          <DialogTitle>Notice</DialogTitle>
          <DialogContent>
            <DialogContentText>Thank you for sharing your feedback. You may close this window.</DialogContentText>
            <Box sx={{ mt: 1 }}>
              <TextField
                autoFocus
                margin="dense"
                id="feedback"
                label="Optional Additional Feedback"
                type="text"
                fullWidth
                variant="standard"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Submit</Button>
          </DialogActions>
        </Dialog>
        {!showDialog && (
          <Box position="fixed" height={"100vh"} width="100vw" display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h6" align="center">
              You can now close this window.
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
};
